import React, {useEffect, useState} from "react";
import useLadyService from "@/services/LadyService";
import {Button, CheckboxFilter, Dropdown, Icon, InputNick, InputRangeFilter} from "@/components/ui";
import {
  allFilter,
  defaultRangeState,
  rangeChange,
  rangeState,
  resetAll,
  setSearchFilter
} from "@/stores/slices/filterSlice";
import {
  cleanSorted,
  getAllSorted,
  setPageDefault,
  setSearchValue,
  setSortedModelsByFilter
} from "@/stores/slices/modelsState";
import {clearTitle} from "@/stores/slices/titleSlice";
import {resetAllCounters} from "@/stores/slices/counterFilterSlice";
import {useSelector} from "react-redux";
import { show } from "@/stores/slices/showFilterSlice";
import {getScrolledClass} from "@/stores/slices/headerSlice";
import { setProfileFilterCount } from "../../../stores/slices/mapModelSlice";

const Filters = () => {
  const {
    dispatch,
    lang,
    windowWidth,
    city,
    t,
    navigate,
  } = useLadyService();

  const options_place = {
    main_title: t("place"),
    options: [
      {
        id: "placeIncall-1",
        forDisabled: "placeIncall",
        title: t("incall"),
        name: "place",
        value: "incall",
        count: "incall",
        checked: false,
      },
      {
        id: "placeOutcall-1",
        forDisabled: "placeOutcall",
        title: t("outcall"),
        name: "place",
        count: "outcall",
        value: "outcall",
        checked: false,
      },
      {
        id: "placeSalon-1",
        forDisabled: "placeSalon",
        title: t("brothel"),
        name: "place",
        value: "salon",
        count: "salon",
        checked: false,
      },
      {
        id: "placeEscort-1",
        forDisabled: "placeEscort",
        title: t("accompaniment"),
        name: "place",
        value: "escort",
        count: "escort",
        checked: false,
      },
    ],
  };

  const scrolledClass = useSelector(getScrolledClass);
  const allStateFilter = useSelector(allFilter);
  const defaultRangeValue = useSelector(defaultRangeState);
  const sortedValue = useSelector(getAllSorted);

  

  const [hasNonEmptyRange, setHasNonEmptyRange] = useState(false);
  const [hasNonEmptyFilters, setHasNonEmptyFilters] = useState(false);

  useEffect(() => {
    checkForEmtyFilters()
  }, [allStateFilter])
  const checkForEmtyFilters = () => {
    const { accordionSelected, range, ...fieldsToCheck } = allStateFilter;

    function allValueIsNull(obj) {
      for (let key in obj) {
        if (obj[key].min !== 0 && obj[key].max !== 0) {
          return true;
        }
      }
      return false;
    }
    //check if range is not empty
    if (allValueIsNull(defaultRangeValue) && allValueIsNull(range)) {
      setHasNonEmptyRange(
        range.price.min > defaultRangeValue.price.min ||
        range.price.max < defaultRangeValue.price.max
          ? true
          : range.age.min > defaultRangeValue.age.min ||
          range.age.max < defaultRangeValue.age.max
            ? true
            : range.weight.min > defaultRangeValue.weight.min ||
            range.weight.max < defaultRangeValue.weight.max
              ? true
              : range.height.min > defaultRangeValue.height.min ||
                    range.height.max < defaultRangeValue.height.max
      );
    }
    //check if filters are not empty
    setHasNonEmptyFilters(Object.values(fieldsToCheck).some((filter) => Array.isArray(filter) && filter.length > 0) || sortedValue.includes('incall'));
  }

  const handleResetFilter = () => {
      dispatch(resetAll());
      dispatch(setSearchFilter(false));
      dispatch(cleanSorted());
      dispatch(setSortedModelsByFilter(""));
      dispatch(clearTitle());
      dispatch(resetAllCounters());
      dispatch(setPageDefault());
      dispatch(setSearchValue(""));
      dispatch(setProfileFilterCount(null));
      navigate(`/${lang}/${city}`);
  }

  useEffect(() => {
    dispatch(
      rangeChange({
        name: "price",
        min: defaultRangeValue.price.min,
        max: defaultRangeValue.price.max,
      })
    );
    dispatch(
      rangeChange({
        name: "age",
        min: defaultRangeValue.age.min,
        max: defaultRangeValue.age.max,
      })
    );
    dispatch(
      rangeChange({
        name: "height",
        min: defaultRangeValue.height.min,
        max: defaultRangeValue.height.max,
      })
    );
    dispatch(
      rangeChange({
        name: "weight",
        min: defaultRangeValue.weight.min,
        max: defaultRangeValue.weight.max,
      })
    );
  }, [defaultRangeValue]);

  return (
    <form className="header__filters">
      {windowWidth > 1199.98 ? (
        <>
          <InputNick
            type="search"
            placeholder={t("modelname")}
            id="headerSearch"
            clazz={"search__input"}
            name={"nickname"}
            scrolledClass={scrolledClass}
          />

          <Dropdown
            title={t("place")}
            name={"place"}
            clazzWrapper={"header__filter--place"}
            size={"s"}
          >
            {options_place.options.map((i) => {
              return (
                <CheckboxFilter
                  options={options_place}
                  key={i.id}
                  name={i.name}
                  id={i.id}
                  value={i.value}
                  count={i.count}
                  title={i.title}
                  forDisabled={i.forDisabled}
                  disabled={true}
                  checked={i.checked}
                  important
                />
              );
            })}
          </Dropdown>

          <Dropdown
            title={t("price")}
            name={"price"}
            clazzWrapper={"header__filter--price"}
            size={"s"}
          >
            <InputRangeFilter
              min={defaultRangeValue.price.min}
              max={defaultRangeValue.price.max}
              step={10}
              clazz={"range--price"}
              inputIdLeft={"minPrice"}
              inputIdRight={"maxPrice"}
              name={"price"}
              title={`${t("price")},`}
              subtitle={`${lang === "cz" ? " Kč" : " €"}/${t("hour")}`}
              price
            />
          </Dropdown>

          <Dropdown
            title={t("age")}
            name={"age"}
            size={"s"}
            clazzWrapper={"header__filter--age"}
          >
            <InputRangeFilter
              min={defaultRangeValue.age.min}
              max={defaultRangeValue.age.max}
              step={1}
              clazz={"range--age"}
              inputIdLeft={"minAge"}
              inputIdRight={"maxAge"}
              title={`${t("age")},`}
              name={"age"}
              subtitle={` ${t("years")}`}
            />
          </Dropdown>
        </>
      ) : null}

      <Button
        onClick={() => {
          dispatch(show());
          checkForEmtyFilters();
        }}
        size={"s"}
        clazz={"header__filter"}
      >
        {t("filter")}
        <Icon size={"m"} spritePath={"filter"} />
        {(hasNonEmptyFilters || hasNonEmptyRange) && (
          <span className="count">!</span>
        )}
      </Button>

      {(hasNonEmptyFilters || hasNonEmptyRange) && (
        <Button
          onClick={handleResetFilter}
          size={"s"}
          title={t("reset")}
          clazz="header__reset hover"
        >
          {t("reset")}
        </Button>
      )}
    </form>
  );

}

export default Filters